import React from 'react';
import { NavLink } from 'react-router-dom';
import { ImageUrl } from '../HelperFunctions';

function ProductsDropdown({id}) {
  return (
    <div className='dropdown-container' id={id}>
        <NavLink to="/" className='one-product'>
            <div className='one-product__image'>
                <img alt="Neodocs wellness care kit" src={ImageUrl('poct/services/general/WellnessKit.png')}></img>
            </div>
            <div className='one-product__name'>Wellness Care Kit</div>
        </NavLink>
        {/* <NavLink to="/products/pregnancy" className='one-product'>
            <div className='one-product__image'>
                <img alt="Neodocs pregnancy care kit" src={ImageUrl('poct/services/pregnancy/pregnancy-kit.png')}></img>
            </div>
            <div className='one-product__name'>Pregnancy Care Kit</div>
        </NavLink> */}
        <NavLink to="/products/uti" className='one-product'>
            <div className='one-product__image'>
                <img alt="Neodocs wellness care kit" src={ImageUrl('poct/services/uti/uti-kit.png')}></img>
            </div>
            <div className='one-product__name'>UTI Care Kit</div>
        </NavLink>
        <NavLink to="/products/kidney" className='one-product'>
            <div className='one-product__image'>
                <img alt="Neodocs wellness care kit" style={{padding:'0.7rem 1rem'}} src={ImageUrl('poct/new-mockups/kidney_packaging.png')}></img>
            </div>
            <div className='one-product__name'>Kidney Care Kit</div>
        </NavLink>
        <NavLink to="/products/sperm" className='one-product'>
            <div className='one-product__image'>
                <img alt="Neodocs sperm count kit" style={{padding:'0.7rem 1rem'}} src={ImageUrl('poct/services/sperm/sperm-kit.png')}></img>
            </div>
            <div className='one-product__name' >Sperm Count Kit</div>
        </NavLink>
        <NavLink to="/products/iconceive" className='one-product'>
            <div className='one-product__image'>
                <img alt="Neodocs ovulation kit" style={{padding:'0.7rem 1rem'}} src={ImageUrl('poct/services/ovulation/ovulationbox.png')}></img>
            </div>
            <div className='one-product__name' >Ovulation Kit</div>
        </NavLink>
    </div>
  )
}

export default ProductsDropdown