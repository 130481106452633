// technology, research, sales, finance, operations
export const CareerStructure = {
    technology:{
        technology123:{
           role_heading:'Python Developer',
           role_type:'full time',
           location:'Mumbai (Goregaon & Powai)',
           introduction:'We are looking for a HIGHLY MOTIVATED candidate to work on cutting edge medical diagnostics that we are developing. The candidate will work with our Development team participating at all levels.',
           mission:['Write effective and scalable code',
           'Test and debug programs',
           'Create and apply the most accurate algorithms to datasets in order to find solutions'],
           requirements:['Experience and knowledge about Python and Linux OS is preferred',
            'Should be Proficient in statistics and statistical packages like Excel to be used for data set analyzing',
            'Must have strong mathematical skills to help collect, measure, organize and analyze data',
            'Good problem-solving skills, accuracy and attention to detail',
            'Able to Adept at queries and writing reports',
            'BSc in Computer Science, Engineering or relevant field'],
            redirect_link:'https://angel.co/l/2xgiMN',
            small_introduction:'We are looking for a candidate to work on cutting edge medical diagnostic technology that we are developing. The candidate will work with our Development team participating at all levels.',
            is_closed:false
        },
        technology124:{
            role_heading:'Graphic Designer',
            role_type:'full time',
            location:'Mumbai (Goregaon & Powai)',
            introduction:'We are looking for a HIGHLY MOTIVATED candidate to work on cutting edge medical diagnostics that we are developing. The candidate will work with our Content creative and Marketing team participating at all levels starting from scratch till end-to-end clinical validation and presentation of results.',
            mission:['Work with your fellow designers and assigned copywriters to create new and refreshing designs and creatives.',
            'Create compelling and effective logos, designs, print, and digital media',
            'Collaborate with the team to ensure consistency of designs across various media outlets',
            'Collaborate with the content team to conceptualize unique brand communication.',
            'Suggest innovations and live outside the box.',
            "Bring your vision and concepts to life -it's not only welcomed but celebrated.",
            "Understand the right balance between communication (narrative) and design such that it gets the message across.",

        ],
            requirements:['2+ Experience working in this field is mandatory',
             'Good Communication Skills',
             'Skills in animation, video editing etc. would be a plus point',
             'Ability to create visual concepts',
             'Has a data-driven mindset and a creative heart.',
             'Interest/ passion for Digital Marketing, Branding & Communications',
             'Strong Technical Skills in Photoshop, Illustrator & Corel DRAW',
             'Eager to learn and explore new aspects of design and implement them.'

            ],
             redirect_link:'https://angel.co/l/2xmruF',
             small_introduction:'We are looking for a HIGHLY MOTIVATED candidate to work on cutting edge medical diagnostics that we are developing.',
             is_closed:true
         }
    },
    research:{
        reasearch1:{
            role_heading:'Research Scientist',
            role_type:'full time',
            location:'Mumbai (Goregaon & Powai)',
            introduction:'We are looking for a HIGHLY MOTIVATED candidate to work on cutting edge medical diagnostics that we are developing. The candidate will work with our Research & Development team participating at all levels starting from scratch till end-to-end clinical validation and presentation of results.',
            mission:["Testing existing assays in both artificial & clinical samples","Developing artificial samples and assays","Working on chemistries to develop new tests"," Performing Testing & Validation on developed chemistries in both artificial & real samples","Compiling all the data and generating insights and knowledge from it", "Presenting the generated knowledge in form of whitepapers, publications at different forums","Brainstorming with the team to come up with new ideas for building the future of diagnostics"],
            requirements:['You have experience working in a wet lab',
             'You have experience working with biochemistries',"You have experience / know-how of : enzymatic chemistries, antigen-antibody based chemistries, sandwiched ELISA's","You have knowledge of using/principles of analytical lab instruments : UV-Vis spectroscopy, ELISA plate readers, HPLC, etc.","You believe that point of care diagnostics and screening is the future of healthcare regime"],
             redirect_link:'https://angel.co/l/2xgiGn',
             small_introduction:'HIGHLY MOTIVATED candidate to work on cutting edge medical diagnostics that we are developing. The candidate will work with our Research & Development team participating at all levels starting from scratch till end-to-end clinical validation and presentation of results.',
             is_closed:false
         }
    },
    sales:{},
    finance:{},
    operations:{}
}